import {
  FeatureProps,
  FeatureSection,
  FeatureSectionProps,
} from "@areahub/components"
import { Icon } from "@chakra-ui/react"
import Image from "next/image"
import React, { ReactElement } from "react"
import { FaCheck } from "react-icons/fa"
interface FeaturesProps
  extends Omit<FeatureSectionProps, "features" | "children"> {
  features: Omit<FeatureProps, "icon">[]
  image: {
    width: number
    height: number
    src: string
    alt: string
  }
}

export default function Features({
  features,
  image,
  ...props
}: FeaturesProps): ReactElement {
  return (
    <FeatureSection
      {...props}
      features={features.map(x => ({ ...x, icon: <Icon as={FaCheck} /> }))}
      sx={{
        ".feature-icon": {
          bg: "red.500",
        },
      }}
    >
      <Image objectFit="cover" {...image} loading="lazy" />
    </FeatureSection>
  )
}
