/* eslint-disable import/no-anonymous-default-export */
import {
  ButtonLink,
  ExternalButtonLink,
  PromptsSearch,
} from "components/CallToAction"

export default {
  hero: {
    title: "Wildfires Near You",
    subtitle:
      "Understand wildfires based on the latest incident report information available",
  },
  currentStats: {
    title: "What's Happening Now?",
    subtitle: "Discover fire news and recent wildfire figures.",
  },
  features: {
    title: "Understand Your Wildfire Risk",
    features: [
      {
        title: "Find active wildfires near you",
        children:
          "Search your area for frequently updated wildfire data to provide you with the latest fire news available, like new large wildfires, acres burned, and more.",
        cta: <PromptsSearch>Fire Near Me</PromptsSearch>,
      },
      {
        title: "See wildfires across the nation",
        children:
          "Check out wildfires across the United States with our interactive nationwide fire map.",
        cta: <ButtonLink href="/now">Fire Map</ButtonLink>,
      },
      {
        title: "Explore ways to reduce your risks of wildfires",
        children: "Learn the steps that may lower your home’s wildfire risk.",
        cta: (
          <ExternalButtonLink href="https://areahub.com/knowledge-center/natural-hazards/wildfires">
            Learn More
          </ExternalButtonLink>
        ),
      },
    ],
    image: {
      src: "https://images.unsplash.com/photo-1507680465142-ef2223e23308?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1296&q=80",
      alt: 'Photo by <a href="https://unsplash.com/@marcus_kauffman?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Marcus Kauffman</a> on <a href="https://unsplash.com/s/photos/wildfire?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>',
      width: 1220,
      height: 942,
    },
  },
  cta: {
    title: `Uncover Even More`,
    secondLine: "With AreaHub!",
    // secondLineSx: {
    //   bgGradient: "linear(to-l, blue.700, blue.500)",
    //   bgClip: "text",
    // },
    subtitle:
      "AreaHub is a one-stop nationwide database of climate, natural and environmental hazards in the United States that produces search results for hurricanes, superfund sites, air quality and more!",
    image: {
      src: "https://images.unsplash.com/photo-1616164744857-1439f3dd5687?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
      alt: "Uncover even more with AreaHub!",
      htmlWidth: 1350,
      htmlHeight: 900,
      loading: "lazy",
      objectFit: "cover",
    },
    tagline: "Climate & Environmental Hazards",
  },
}
