import useSWR from "swr"
import { FHIncidentResponse } from "types/fh-incidents"

export const useWildfireLocationsMap = () => {
  const endpoint = `/api/wildfire-locations-map`
  const { data, isValidating } = useSWR<FHIncidentResponse, {}>(endpoint)
  const loading = isValidating || !data
  return { data, loading }
}

export default useWildfireLocationsMap
