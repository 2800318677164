import { StatsDisplayWithCards } from "@areahub/components"
import type { StatDisplayCardProps } from "@areahub/components/dist/Presentation/StatsDisplay/WithCards/StatDisplayCard"
import React from "react"
import useCurrentWildfireStats from "utils/hooks/use-current-wildfire-stats"
interface CurrentStatsProps {
  title: string
  subtitle: string
  accentColor?: string
}

const CurrentStats = (props: CurrentStatsProps) => {
  const stats = useCurrentWildfireStats()
  return (
    <StatsDisplayWithCards
      bg="red.500"
      stats={stats as StatDisplayCardProps[]}
      {...props}
    />
  )
}

export default CurrentStats
