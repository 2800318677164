import { Button, ButtonProps } from "@chakra-ui/button"
import { noOutline } from "configs/common"
import Link from "next/link"
import React, { ForwardedRef, ReactElement } from "react"
import { useGlobalSearch } from "utils/hooks/use-global-search-places"
interface CTAProps extends ButtonProps {
  href?: any
  target?: string
  rel?: string
}

export const CTA = React.forwardRef(
  (props: CTAProps, ref: ForwardedRef<any>): ReactElement => {
    return (
      <Button
        ref={ref}
        colorScheme="red"
        size="md"
        maxW={{
          base: "150px",
        }}
        {...noOutline}
        {...props}
      />
    )
  }
)

export function PromptsSearch(props: CTAProps): ReactElement {
  const modal = useGlobalSearch()
  return <CTA onClick={modal.onOpen} {...props} />
}
export const ButtonLink = ({ href, ...props }: CTAProps) => (
  <Link href={href} passHref>
    <CTA as="a" {...props} />
  </Link>
)

export const ExternalButtonLink = ({ href, ...props }: CTAProps) => (
  <CTA
    as="a"
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    {...props}
  />
)
