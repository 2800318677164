import clientFetcher from "lib/client-fetcher"
import useSWR from "swr"

export const defaultStats = [
  {
    title: "Active Large Fires Now",
    loading: true,
    accentColor: "red.500",
  },
  { title: "New Large Fires", loading: true, accentColor: "red.500" },
  {
    title: "Wildfires YTD",
    loading: true,
    accentColor: "red.500",
  },
  {
    title: "Acres Burned by Large Fires",
    loading: true,
    accentColor: "red.500",
  },
  {
    title: "Personnel Assigned to Wildfires",
    loading: true,
    accentColor: "red.500",
  },
  {
    title: "Acres Burned YTD",
    loading: true,
    accentColor: "red.500",
  },
]

export default function useCurrentWildfireStats() {
  const statsUrl = `/api/current-wildfire-stats`
  const { data } = useSWR(statsUrl, clientFetcher, {
    fallbackData: defaultStats,
  })
  return data
}
