import { fetchPostById } from "@areahub/lib"
import CTALeft from "components/Sections/CTALeft"
import CurrentStats from "components/Sections/CurrentStats"
import Features from "components/Sections/Features"
import Hero from "components/Sections/Hero"
import WildfireArticle from "components/Sections/WildfireArticle"
import content from "configs/home"
import Layout from "layout/Layout"
import type { GetStaticProps, NextPage } from "next"
import Head from "next/head"
export const getStaticProps: GetStaticProps = async (context) => {
  const wildfireArticle = await fetchPostById("60facdfbad3030743efeb1e4")
  return {
    props: { wildfireArticle },
  }
}

const Home: NextPage = ({ wildfireArticle }: any) => {
  return (
    <Layout>
      <Head>
        <link
          rel="preload"
          href="/api/wildfire-locations-map"
          as="fetch"
          crossOrigin=""
        />
      </Head>
      <Hero {...content.hero} />
      <CurrentStats {...content.currentStats} />
      <Features {...content.features} />
      <WildfireArticle article={wildfireArticle} />
      <CTALeft {...content.cta} />
    </Layout>
  )
}

export default Home
