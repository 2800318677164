import { CTAImageLeft, CTAImageLeftProps } from "@areahub/components"
import { Link, Stack } from "@chakra-ui/react"
import { externalLink, mainSite, noOutline } from "configs/common"
import { ReactElement } from "react"

interface CTALeftProps extends CTAImageLeftProps {}

export default function CTALeft(props: CTALeftProps): ReactElement {
  return (
    <CTAImageLeft
      {...props}
      containerSx={{
        as: "section",
      }}
    >
      <Stack
        direction={{ base: "column", sm: "row" }}
        mt={{ base: 8, lg: 0 }}
        shrink={{ lg: 0 }}
        spacing="4"
        w="full"
      >
        <Link
          as="a"
          href={mainSite}
          w={{ base: "full", md: "auto" }}
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          px={5}
          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          color="white"
          bg="primary.600"
          _hover={{
            bg: "primary.500",
          }}
          {...externalLink}
          {...noOutline}
        >
          Run an AreaHub Report
        </Link>
        <Link
          as="a"
          href={`${mainSite}`}
          w={{ base: "full", md: "auto" }}
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          px={5}
          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          color="primary.600"
          bg="white"
          _hover={{
            bg: "primary.50",
          }}
          {...externalLink}
          {...noOutline}
        >
          Go to AreaHub
        </Link>
      </Stack>
    </CTAImageLeft>
  )
}
