import { FeaturedBlog } from "@areahub/components"
import { knowledgeCenterLink } from "configs/common"

export interface WildfireArticleProps {
  article: any
}

const WildfireArticle = ({ article }: WildfireArticleProps) => {
  return (
    <FeaturedBlog
      title={article.title}
      excerpt={article.excerpt}
      media={{
        ...article.image,
        imageProps: {
          htmlHeight: 1334,
          htmlWidth: 2000,
          loading: "lazy",
        },
      }}
      author={article.author}
      meta={{
        type: "Knowledge Center",
        color: "red",
        tags: [],
      }}
      onClick={() =>
        window && article?.slug
          ? window?.open(
              `${knowledgeCenterLink}/natural-hazards/${article?.slug}`,
              "_blank"
            )
          : undefined
      }
    />
  )
}

export default WildfireArticle
