import {
  CenteredWithStats,
  CenteredWithStatsProps,
  SearchPlacesInputButton,
} from "@areahub/components"
import { Box, BoxProps, Flex } from "@chakra-ui/layout"
import { Button, useBreakpointValue } from "@chakra-ui/react"
import { HeroSvgMap } from "components/Map/HeroSvgMap"
import Link from "next/link"
import { Fragment } from "react"
import { useGlobalSearch } from "utils/hooks/use-global-search-places"
function HeroContentContainer(
  props: Omit<CenteredWithStatsProps, "children" | "sx">
) {
  const modal = useGlobalSearch()
  return (
    <CenteredWithStats
      {...props}
      sx={{
        bg: {
          base: "baseMapBg",
          md: "transparent",
        },
        color: "text",
        h: {
          base: "auto",
          md: "640px"
        },

        pt: {
          base: "2",
          md: "auto"
        }
      }}
      titleSx={{
        size: "xl"
      }}
      subtitleSx={{
        size: "lg"
      }}
    >
      <Box
        w={{
          base: "xs",
          md: "md",
          lg: "lg",
        }}
      >
        <SearchPlacesInputButton
          onClick={() => modal.onOpen()}
        />
        <Flex>
          <Button
            colorScheme="red"
            fontSize="md"
            _focus={{
              outline: "none",
            }}
            w={{
              base: "sm",
              lg: "full",
            }}
            mr="2"
            onClick={() => modal.onOpen()}
          >
            Search Wildfires
          </Button>
          <Button
            bg="gray.700"
            color="white"
            _hover={{
              bg: "gray.800",
            }}
            _focus={{
              outline: "none",
            }}
            fontSize="md"
            w={{
              base: "sm",
              lg: "full",
            }}
            ml="2"
          >
            <Link href="/now">Interactive Map</Link>
          </Button>
        </Flex>
      </Box>
    </CenteredWithStats>
  )
}

export default function Hero(
  props: Omit<CenteredWithStatsProps, "children" | "sx">
) {
  const styleProps: BoxProps | undefined = useBreakpointValue<BoxProps>({
    base: {
      pos: "relative",
      height: "250px",
    },
    md: {
      pos: "absolute",
      top: "0",
      bottom: "0",
      right: "0",
      left: "0",
      height: "640px"
    }
  })
  return (
    <Fragment>
      <Box
        {...styleProps}
      >
        <HeroSvgMap />
      </Box>
      <HeroContentContainer {...props} />
    </Fragment>
  )
}