import { FireInfo, SvgMap } from "@areahub/components"
import { colors } from "@areahub/theme"
import { Box } from "@chakra-ui/layout"
import { useBreakpointValue } from "@chakra-ui/media-query"
import useWildfireLocationsMap from "utils/hooks/use-wildfire-locations-map"
export function getColorByPercentContainment(
  containment: number,
  colorKey?: Array<{ percent: number; color: string }>
): string {
  // Default color here
  let ret = colors.gray[300]
  // Iterate through the list of percents/colors. colorKey must be sorted ascending percent
  for (const { percent, color } of colorKey || []) {
    ret = color
    if (containment < percent) {
      break
    }
  }
  // Returns color corresponding to the next highest containment percent
  return ret
}

export const HeroSvgMap = () => {
  // Get and parse the fire incident/location data
  const { data } = useWildfireLocationsMap()
  const colorKey = useBreakpointValue({
    base: [
      { percent: 50, color: colors.red[500] },
      { percent: 60, color: colors.orange[500] },
      { percent: 75, color: colors.amber[500] },
      { percent: 95, color: colors.yellow[500] },
      { percent: 100, color: colors.yellow[300] },
    ],
    md: [
      { percent: 50, color: colors.red[300] },
      { percent: 60, color: colors.orange[300] },
      { percent: 75, color: colors.amber[200] },
      { percent: 95, color: colors.yellow[200] },
      { percent: 100, color: colors.yellow[100] },
    ],
  })
  const fires: FireInfo[] =
    data?.features
      // Filter out fires with incorrect # of lat/lon coordinates
      ?.filter((fire) => fire.geometry.coordinates.length >= 2)
      ?.map((fire) => {
        // Coords of the fires
        const coords = fire.geometry.coordinates
        const lonLat: [number, number] = [coords[0], coords[1]]

        // Radius of the fires
        const dailyAcres = fire.properties.DailyAcres
        const radius = dailyAcres > 10 ? Math.log(dailyAcres) / 1.5 : 1

        // Color of the fires
        const color = getColorByPercentContainment(
          fire.properties.PercentContained,
          colorKey
        )

        return {
          lonLat,
          color,
          radius,
        }
      }) || []
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100% !important"
      bg="baseMapBg"
      // Style the inner svg map
      sx={{
        ".rsm-svg": {
          width: "auto",
          height: "100%",
        },
      }}
    >
      <SvgMap points={fires} allowSelect={false} />
    </Box>
  )
}
